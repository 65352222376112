export function getPlaceholderHeight(width, height) {
  return `${((height / width) * 100).toFixed(2)}%`
}

// 页面标识映射pageName -> ccc pageType
export const PAGE_NAME_MAP_PAGE_KEY = {
  page_real_class: 'page_real_class',
  page_select_class: 'page_select_class',
  page_daily_new: 'daily_new',
  page_selling_point: 'page_selling_point',
  page_goods_group: 'shein_picks',
  page_picked: 'page_picked',
  page_search: 'page_search'
}


export function getIsCustomAttr (attr, key = 'attr_values') {
  return attr?.[key]?.some(item => item?.[key]?.length > 0)
}
