import schttp from 'public/src/services/schttp'
import { getLocalStorage } from '@shein/common-function'

// 展示密码修改弹窗
export function showPopUpdatePassword (data = {}) {
  () => {
    const isShowed = getLocalStorage('is_show_init_passwd') || ''
    const { user } = data 
    if (isShowed) return // 已取消
    if (isShoppingProcess()) return // 购物流程

    if (user) {
      if (user.init_password && user.init_password == 1) {
        window.userExpireDate = user.expire_date
        getPopModalAbtresult()
      }
      return
    }
    schttp({
      method: 'POST',
      url: '/api/user/auth/userInfo/get',
    }).then((res) => {
      if (res && res.init_password && res.init_password == 1) {
        window.userExpireDate = res.expire_date
        getPopModalAbtresult()
      }
      return
    })
  }
}

if (typeof window !== 'undefined') {
  window.showPopUpdatePassword = showPopUpdatePassword
}

function getPopModalAbtresult () {
  window._abt_server_provider.getUserAbtResult({ posKeys: 'ChangePassword' }).then((abtinfo) => {
    const data = abtinfo['ChangePassword']
    if (data && data.param && data.param.toLowerCase() === 'on') {
      loadUpdatePasswordModal()
    }
  })
}

const pageNameForShoppingProcess = ['page_cart', 'page_checkout', 'page_checkout_again', 'page_order_list', 'page_order_detail', 'page_sms_confirm']
// 是否是购物流程页面
function isShoppingProcess () {
  const { page_name } = window.SaPageInfo || window.getSaPageInfo || {}
  return pageNameForShoppingProcess.includes(page_name)
}

function loadUpdatePasswordModal () {
  const div = document.createElement('div')
  div.className = 'j-popup-update-password'
  document.body.appendChild(div)
  import(/* webpackChunkName: "update-password-module" */ './index').then(() => {})
}
