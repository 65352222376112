// 转成tree组件通用字段
const formatData = ({ data, name, id, subTree, isOpen, lastIndex, selected }) => {
  data.lastIndex = data[lastIndex]
  data.children.forEach(item => {
    const label = item[name]
    const label_id = item[id]
    // const parent_id = item[pId]
    const children = item[subTree]
    const open = item[isOpen]
    const isSelected = item[selected]

    Object.keys(item).forEach(key => {
      delete item[key]
    })
    Object.assign(item, { label, label_id, open, children, isSelected })

    if (item.children && Array.isArray(item.children) && item.children.length) {
      formatData({ data: item, name, id, subTree, isOpen, lastIndex, selected })
    }
  })
  return data
}

export {
  formatData
}

