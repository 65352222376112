export default {
  methods: {
    handleUrl(isClickAttr) {
      if (
        this.searchKeywords.is_suggest_res &&
        this.searchKeywords.suggest_words
      ) {
        this.jumpUrl({
          localUrl: `${location.origin}/pdsearch/${this.searchKeywords.suggest_words}`,
          queryObj: this.queryObj,
          isClickAttr,
        })
      } else {
        this.jumpUrl({ queryObj: this.queryObj, isClickAttr })
      }
    },
  },
}
