<template>
  <div
    v-show="display"
    class="product-list__item"
  >
    <a
      class="S-product-item__wrapper btn-next-page"
      href="javascript:;"
      tabindex="0"
      @click="handleNextHolderClick"
      @keydown.enter.stop="handleNextHolderClick"
    >
      <span class="product-list__next-holder">
        {{ language.SHEIN_KEY_PC_17300 }}
        <i class="svgicon svgicon-nextpage"></i>
      </span>
    </a>
  </div>
</template>

<script>
import 'public/src/icon/nextpage.svg'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { parseQueryString, debounce } from '@shein/common-function'
import mixin from './filter/mixins'
export default {
  name: 'ProductListNextHolder',
  mixins: [mixin],
  data() {
    return {
      display: true
    }
  },
  computed: {
    ...mapState(['language', 'IS_RW']),
    ...mapGetters(['catInfo', 'searchKeywords']),
  },
  mounted() {
    this.$nextTick(() => {
      this.shouldBeShow()
    })
    window.addEventListener(
      'resize',
      debounce({ func: () => this.shouldBeShow(), wait: 500 })
    )
  },
  methods: {
    ...mapMutations(['jumpUrl']),
    handleNextHolderClick() {
      this.queryObj = parseQueryString(location.search)
      this.queryObj.page = Number(this.catInfo.page) + 1

      // mixin方法
      this.handleUrl()

      this.$emit('clickNextPage', this.queryObj.page)
    },
    shouldBeShow() {
      this.display = true
      this.$nextTick(() => {
        const holder = this.$el.getBoundingClientRect().left
        const productListInstance = this.$parent.$parent.$refs.goodsItem || document.querySelectorAll('section.product-list__item')
        const atLastDom = productListInstance ? productListInstance[productListInstance.length - 1]?.$el || productListInstance[productListInstance.length - 1] : ''
        if (!atLastDom) return (this.display = false)
        const last = atLastDom.getBoundingClientRect().left
        this.display = gbCommonInfo.GB_cssRight ? holder < last : holder > last
      })
    },
  },
  emits: ['clickNextPage'],
}
</script>

<style lang="less">
.product-list-v2 {
  .product-list {
    .btn-next-page {
      height: 100%;
    }
    &__next-holder {
      position: absolute;
      top: 50%;
      left: 50% /*rtl:ignore*/;
      transform: translate(-50%, -50%) /*rtl:ignore*/;
      font-size: 14px;
      text-align: center;
      white-space: nowrap;
      .svgicon {
        display: block;
        width: 24px;
        height: 24px;
        margin-top: 20px;
      }
    }
  }
}
</style>
