import { daEventCenter } from 'public/src/services/eventCenter'

function getFilterInfo(catInfo) {
  const { daily, child_cat_id, tsp_ids, attr_ids, min_price: curMin, max_price: curMax } = catInfo
  const priceRange = curMin && curMax ? `${curMin}\`${curMax}` : ''
  const filterInfo = {}
  daily && (filterInfo.date = daily)
  child_cat_id && (filterInfo.categoryId = child_cat_id)
  tsp_ids && (filterInfo.tsps = tsp_ids)
  attr_ids && (filterInfo.attributeList = attr_ids)
  priceRange && (filterInfo.priceRange = priceRange)

  return filterInfo
}

const exposeFilter = ({ panelConfig, ATTR_KEY, CATE_KEY, DAILY_KEY,  }) => {
  const daIdMap = {
    daily: '1-4-2-3',
    cate: '1-4-2-4',
    attr: '1-4-2-10',
  }
  let attrData = []
  panelConfig.forEach((item) => {
    const { key, data } = item

    if (key == ATTR_KEY) {
      attrData = attrData.concat(data.children)
    }

    if (key == CATE_KEY || key == DAILY_KEY) {
      let daId = daIdMap[key] || ''
      // analysis
      daId &&
        daEventCenter.triggerNotice({
          daId,
          extraData: {
            data: data.children || [],
          },
        })
      // analysis
    }
  })

  if (attrData.length) {
    daEventCenter.triggerNotice({
      daId: daIdMap['attr'],
      extraData: {
        data: attrData || [],
      },
    })
  }
}

const clickCategory = (value, facet, gfilter) => {
  daEventCenter.triggerNotice({
    daId: '1-4-2-1',
    extraData: {
      data: value,
      facet,
      gfilter,
    },
  })
}

const clickDaily = (value) => {
  daEventCenter.triggerNotice({
    daId: '1-4-2-2',
    extraData: {
      data: value,
    },
  })
}

const clickAttribute = ({ value, attrFilterArr, facet, gfilter, isHot }) => {
  // 因为hot标是不固定的，不能放在url参数，上报埋点前格式化埋点数据，带上isHot信息
  // function formatAnalysisData(data) {
  //   return data.map((item) => {
  //     const attr_id = item.split('_')[0]
  //     return item
  //       .split('-')
  //       .map(
  //         (_) => _ + (filterHotAttrs[attr_id]?.includes(_) ? '_1' : '_0')
  //       )
  //       .join('-')
  //   })
  // }
  
  daEventCenter.triggerNotice({
    daId: '1-4-2-5',
    extraData: {
      data: value,
      labelId: attrFilterArr.join('-'),
      facet,
      gfilter,
      isHot: isHot ? 1 : 0,
    },
  })
}

const selectPriceRange = ({ value, fhContext, googleContext }) => {
  daEventCenter.triggerNotice({
    daId: '1-4-2-6',
    extraData: {
      data: value,
      facet: fhContext.min_price,
      facet2: fhContext.max_price,
      gfilter: googleContext.gfilter,
    },
  })
}

const clickClearAll = (catInfo) => {
  daEventCenter.triggerNotice({
    daId: '1-4-2-11',
    extraData: {
      ...getFilterInfo(catInfo)
    },
  })
}

const clickTsp = (tspIds) => {
  daEventCenter.triggerNotice({
    daId: '1-4-2-14',
    extraData: {
      tsps: tspIds,
    },
  })
}

const delSelectedFilter = (delSelectedFilterInfo) => {
  daEventCenter.triggerNotice({
    daId: '1-4-2-16',
    extraData: delSelectedFilterInfo
  })
}

const exposeSelectedFilter = (catInfo) => {
  daEventCenter.triggerNotice({
    daId: '1-4-2-15',
    extraData: {
      ...getFilterInfo(catInfo)
    }
  })
}

const exposeClearAll = (catInfo) => {
  daEventCenter.triggerNotice({
    daId: '1-4-2-17',
    extraData: {
      ...getFilterInfo(catInfo)
    }
  })
}

export default {
  exposeFilter,
  clickCategory,
  clickDaily,
  clickAttribute,
  selectPriceRange,
  clickClearAll,
  clickTsp,
  delSelectedFilter,
  exposeSelectedFilter,
  exposeClearAll
}
